import { Button, TextField } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import "./../App.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeywordsUpload from "./KeywordsUpload";
import DescriptionUpload from "./DescriptionUpload";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { Box } from "@mui/system";
import { Parser } from "@json2csv/plainjs";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

const Main = (props) => {
  const [newArr, setNewArr] = useState([]);
  const circleCount = [];
  const [userInput, setUserInput] = useState("");
  const [userInputOuter, setOuterInput] = useState(1);
  const [outerSetCount, setOuterSetCount] = useState();
  const [SeoData, setSeoData] = useState([]);
  const [KeyWord, setKeyWord] = useState();
  const [isLat, setLat] = useState();
  const [isLong, setLong] = useState();
  const [isBaseName, setBaseName] = useState();
  const [isBaseDescription, setBaseDescription] = useState();
  const [isLoader, setLoader] = useState(0);
  // const [finalTable, setFinalTable] = useState([]);

  let cordCount = 2000;

  const getLat = (e) => {
    setLat(e.target.value);
  };
  const getLong = (e) => {
    setLong(e.target.value);
  };
  const initName = (e) => {
    setBaseName(e.target.value);
  };
  const loaderValue = (x, y) => {
    return Math.floor((x / y) * 100);
  };
  const initDescription = (e) => {
    setBaseDescription(e.target.value);
  };

  // 72.9556716
  // 19.1985743

  for (let i = 0.1; i <= userInput; i += 0.1) {
    // console.log(parseFloat(i).toFixed(1), "CIRCL PARSING VALUE");
    circleCount.push(parseFloat(i).toFixed(1));
    // console.log(circleCount, "CIRCLE PARSING VALUE");
  }

  const handleChange = (event) => {
    setUserInput(event.target.value);
  };
  const saveZip = (filename, urls) => {
    if (!urls) return;

    const zip = new JSZip();
    const folder = zip.folder("files"); // folder name where all files will be placed in

    urls.forEach((url, ind) => {
      folder.file(`Circle${ind}.csv`, url);
    });

    // Generate the zip file asynchronously
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Force down of the Zip file
      FileSaver.saveAs(content, "archive.zip");
    });
  };
  const saveZipMultiple = (filename, urlsArray) => {
    if (!urlsArray) return;
    // urls is a single array
    // urls will be an array of arrays
    // below function is of a single array of urls
    const zip = new JSZip();
    for (let i = 0; i < urlsArray?.length; i++) {
      const folder = zip.folder(`Set ${i + 1}`);
      urlsArray.forEach((urls, index) => {
        urls.forEach((url, ind) => {
          folder.file(`Circle${ind}.csv`, url);
        });
      });
    }

    // Generate the zip file asynchronously
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Force down of the Zip file
      FileSaver.saveAs(content, "archive.zip");
    });
  };
  const shuffleES6 = (array) => {
    const newArray = [...array];

    newArray.reverse().forEach((item, index) => {
      const j = Math.floor(Math.random() * (index + 1));
      [newArray[index], newArray[j]] = [newArray[j], newArray[index]];
    });

    return newArray;
  };

  const showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result
        .split(/\r?\n/)
        .filter(
          (ele, ind) => ele !== "name" && ele !== "" && ele !== "description"
        );
      console.log(text, "THE FILE READER READS KEYWORDS");
      // setSeoData(text);
      setSeoData((prevState) => [...prevState, { value: text }]);
      // // console.log(text);
      alert("file uploaded");
      //   alert(text);
    };
    const finalArr = reader.readAsText(e.target.files[0]);
    // console.log(finalArr);
    // ;
  };
  console.log(SeoData, "SEO DATA");

  const loopFunc = (uploadedFiles) => {
    const newArr = [];
    if (uploadedFiles) {
      for (let i = 0; i < cordCount / uploadedFiles.length; i++) {
        for (let j = 0; j < uploadedFiles.length; j++) {
          newArr.push(uploadedFiles[j]);
        }
      }
      // const shuffedArr = shuffleArr(newArr);
      return newArr;
    } else {
      console.log(
        uploadedFiles,
        "There is an error in the way the variable is passed"
      );
    }
  };

  let newSeoArr = [];
  let newKeyWordArr = [];

  const allCircles = (keywords) => {
    // console.log(keywords);
    // console.log(descriptions);
    // 19.198418001986912, 72.9556770938645
    // 25.18528117439559, 55.27562464605748
    const alldata = [];
    let centerLng = props.degToRad(isLong);

    let centerLat = props.degToRad(isLat);
    const mathFunc = (diameter, keywordsArray) => {
      // console.log(keywords)
      // let diameter = diam; // diameter of circle in km
      let dist = (diameter * 25) / 6371.0;
      // console.log(dist, "THE DIAMETER OF THE CIRCLE");
      let allCord = [];

      for (let x = 0; x < cordCount; x++) {
        // console.log(loaderValue(x, cordCount), 'DONE FOR CREATING DATA')
        // setLoader(isLoader + loaderValue(x, cordCount) + 1);
        const keywords = shuffleES6(keywordsArray);
        let brng = props.degToRad(x);
        let latitude = Math.asin(
          Math.sin(centerLat) * Math.cos(dist) +
            1 * Math.cos(centerLat) * Math.sin(dist) * Math.cos(brng)
        );
        let longitude =
          centerLng +
          Math.atan2(
            Math.sin(brng) * Math.sin(dist) * Math.cos(centerLat),
            Math.cos(dist) - Math.sin(centerLat) * Math.sin(latitude)
          );
        // radians_to_degrees(longitude) radians_to_degrees(latitude)
        // console.log(keywords)
        allCord.push({
          name: keywords?.[x]?.name,
          description: keywords?.[x]?.description,
          longitude: props.radToDeg(longitude),
          latitude: props.radToDeg(latitude),
        });
      }
      // console.log(allCord, "all cordinates");
      return allCord;
    };
    circleCount.forEach((ele, ind) => {
      // const circleCords = mathFunc(ele, );
      const circleCords = mathFunc(ele, keywords);
      alldata.push(circleCords);
      // const data = [alldata[ind]];
      // const fileName = "download";
    });
    // console.log(alldata)
    return alldata;
  };
  let csvVariableData = "";
  const largeSetArray = [];

  useEffect(() => {
    newSeoArr = loopFunc(SeoData?.[0]?.value);
    newKeyWordArr = loopFunc(SeoData?.[1]?.value);
    const seoDataSet = [];
    for (let i = 0; i < cordCount; i++) {
      seoDataSet.push({
        name: newSeoArr?.[i],
        description: newKeyWordArr?.[i],
      });
    }

    const newARRAY = shuffleES6(seoDataSet);
    console.log("BASIC SHUFFLE DONE");
    // console.log(newARRAY);
    csvVariableData = allCircles(newARRAY);
    csvVariableData.push([
      {
        name: isBaseName,
        description: isBaseDescription,
        longitude: isLong,
        latitude: isLat,
      },
    ]);
    // console.log(csvVariableData, "READY TO DOWNLOAD 1 SET");
    console.log("I THINK THIS IS SECOND STAGE");

    // MULTIPLE
    for (let i = 0; i < 80; i++) {
      const multiSetCsvVariableData = allCircles(newARRAY);
      multiSetCsvVariableData.push([
        {
          name: isBaseName,
          description: isBaseDescription,
          longitude: isLong,
          latitude: isLat,
        },
      ]);
      largeSetArray.push(multiSetCsvVariableData);
    }
    // console.log(largeSetArray, "READY TO DOWNLOAD LARGE SET");
    console.log("DONE WITH THE LOOPS, CAN DOWNLOAD NOW");
  }, [userInput]);

  const downloadFile = () => {
    const opts = {
      fields: ["name", "description", "longitude", "latitude"],
    };
    const parser = new Parser(opts);
    const csvArr = [];
    console.log(csvVariableData, "SINGLE SET ARRAY THAT IS GOING TO BE PARSED");
    for (let i = 0; i < csvVariableData.length; i++) {
      csvArr.push(parser.parse(csvVariableData[i]));
    }
    console.log(csvArr, "array to be sent to the save as");
    // console.log(csvVariableData, "DOWNLOAD DATA");
    saveZip("array", csvArr);
  };
  const downloadMultiple = async () => {
    const opts = {
      fields: ["name", "description", "longitude", "latitude"],
    };
    const parser = new Parser(opts);
    const largeCSVset = [];
    console.log(largeSetArray[0], "LARGE SET ARRAY THAT IS GOING TO BE PARSED");
    for (let i = 0; i < largeSetArray.length; i++) {
      // console.log(largeSetArray[i]);
      const csvArr = [];
      for (let j = 0; j < largeSetArray[i].length; j++) {
        csvArr.push(parser.parse(largeSetArray[i][j]));
      }
      largeCSVset.push(csvArr);
    }
    console.log(largeCSVset, "array to be sent to the save as");
    // console.log(csvVariableData, "DOWNLOAD DATA");
    saveZipMultiple("array", largeCSVset);
    //
  };

  const outerSetCounts = [];
  if (userInputOuter) {
    for (let i = 0; i < userInputOuter; i++) {
      outerSetCounts.push(i);
    }
  }
  return (
    <>
      <div className="container">
        <div>
          <h1>Google my Maps Circles Generator</h1>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem 0rem",
          }}
        >
          <KeywordsUpload showFile={showFile} />
          <DescriptionUpload showKeyword={showFile} />
        </Box>

        <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0rem" }}>
          <div>
            <h2>Latitude Test</h2>
            <p>72.9556716</p>
          </div>
          <div>
            <h2>Longitude Test</h2>
            <p>19.1985743</p>
          </div>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0rem" }}>
          <TextField
            id="outlined-basic"
            label="Latitude"
            variant="outlined"
            onChange={(e) => getLat(e)}
            type="number"
          />
          <TextField
            type="number"
            id="outlined-basic"
            label="Longitude"
            variant="outlined"
            onChange={(e) => getLong(e)}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "1rem", margin: "1rem 0rem" }}>
          <TextField
            id="outlined-basic"
            label="Center point name/keyword"
            variant="outlined"
            onChange={(e) => initName(e)}
            type="text"
          />
          <TextField
            type="text"
            id="outlined-basic"
            label="Center point description"
            variant="outlined"
            onChange={(e) => initDescription(e)}
          />
        </Box>
        <FormControl>
          <InputLabel sx={{ width: 300 }}>How many circles</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userInput}
            label="userinput"
            onChange={handleChange}
            sx={{ width: 300 }}
          >
            {/* <MenuItem value={0.1}>1</MenuItem>
            <MenuItem value={0.2}>2</MenuItem>
            <MenuItem value={0.31}>3</MenuItem>
            <MenuItem value={0.4}>4</MenuItem>
            <MenuItem value={0.5}>5</MenuItem>
            <MenuItem value={0.6}>6</MenuItem>
            <MenuItem value={0.7}>7</MenuItem>
            <MenuItem value={0.8}>8</MenuItem> */}
            <MenuItem value={0.9}>10</MenuItem>
            {/* <MenuItem value={1}>10</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "1rem" }}>
          <InputLabel sx={{ width: 300 }}>Select Number of Sets</InputLabel>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem 0rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              margin: "1rem 0rem",
            }}
          >
            {/* <Button
              variant="contained"
              sx={{ width: "100%" }}
              onClick={(e) => {
                // downloadFile();
              }}
            >
              Generate
            </Button> */}
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                color="success"
                value={isLoader}
              />
            </Box>
          </Box>
          {/* 
          <Button
            variant={isLoader === 100 ? "outlined" : "disabled"}
            onClick={(e) => {
              downloadFile();
            }}
          >
            GET ONE SET OF CIRCLES
          </Button> */}
          <Button
            // variant={isLoader === 100 ? "outlined" : "disabled"}
            variant="outlined"
            onClick={(e) => {
              downloadMultiple();
            }}
          >
            GET MULTIPLE SETS
          </Button>
        </Box>
      </div>
    </>
  );
};

export default Main;
